$('#vchrCountry select').change(function changeCountry() {
  $.get('/ajax/getStates.cfm?country=' + $(this).val(), function getStates(
    data
  ) {
    $('#vchrState select')
      .html(data)
      .prop('disabled', false);
  });
});
$('#form-left form').submit(function checkRequired() {
  var fail = false;
  var message = '';
  var errorCount = 0;
  var name = '';
  var title = '';
  $('#form-left form [required]').each(function checkFields(index, element) {
    var $element = $(element);
    if ($element.val() === '') {
      $element.css('background', 'red');
      fail = true;
      errorCount++;
      name = $element.siblings('label').replace('*', '');
      message += name + ' is required. ';
    }
  });
  if (fail) {
    title = $(this).attr('data-title');
    $('#modal')
      .html(
        '<p>Form submission failed for the following reason(s):' +
          message +
          '</p>'
      )
      .dialog({
        minHeight: 150,
        width: 300,
        modal: true,
        title: title,
        closeText: 'X',
        buttons: {
          Okay: function closeModal() {
            $(this).dialog('close');
          }
        }
      });
    return false;
  }
  return true;
});

// file check
if($('.custom-quote').length) {
  /*
  const fileInput = document.querySelector('input[type="file"]');
  fileInput.addEventListener('change', () => {
    const file = fileInput.files[0];
    if (file) {
      const mimeType = file.type;
      console.log(mimeType);
    }
  });*/

/*
  const express = require('express');
  const multer = require('multer');
  const fs = require('fs');
  const path = require('path');

  function detectMimeType(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function(event) {
        const buffer = new Uint8Array(event.target.result);
        let mime = 'application/octet-stream'; // Default
        
        if (buffer[0] === 0xFF && buffer[1] === 0xD8 && buffer[2] === 0xFF) {
            mime = 'image/jpeg';
        } else if (buffer[0] === 0x89 && buffer[1] === 0x50 && buffer[2] === 0x4E && buffer[3] === 0x47) {
            mime = 'image/png';
        } else if (buffer[0] === 0x25 && buffer[1] === 0x50 && buffer[2] === 0x44 && buffer[3] === 0x46) {
            mime = 'application/pdf';
        }
        resolve(mime);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }
  
  const fileInput = document.querySelector('input[type="file"]');
  fileInput.addEventListener('change', async () => {
    console.log('hello there')
      const file = fileInput.files[0];
      if (file) {
          const mimeType = await detectMimeType(file);
          console.log(mimeType);
      }
  });
  */
  function detectMimeType(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = function(event) {
        const uint = new Uint8Array(event.target.result);
        let bytes = [];
        uint.forEach(byte => {
          bytes.push(byte.toString(16));
        });
        const magicNumber = bytes.join('').toUpperCase();
        let mimeType = 'application/octet-stream'; // Default MIME type
  
        if (magicNumber.startsWith('89504E47')) {
          mimeType = 'image/png';
        } else if (magicNumber.startsWith('FFD8FF')) {
          mimeType = 'image/jpeg';
        } else if (magicNumber.startsWith('25504446')) {
          mimeType = 'application/pdf';
        } // Add more checks for other file types as needed
  
        resolve(mimeType);
      };
  
      reader.onerror = function() {
        reject(new Error('Error reading file.'));
      };
  
      reader.readAsArrayBuffer(file);
    });
  }
  
  //const fileInput = document.getElementById('fileInput');
  const fileInput = document.querySelectorAll('input[type="file"]');
  let formSubmit = document.querySelector('.check-captcha');
  
  fileInput.forEach(fileUpload => {
    fileUpload.addEventListener('change', async (event) => {
      const file = event.target.files[0];
      //console.log(file);
      if (file) {
        try {
          const mimeType = await detectMimeType(file);
          //console.log(`MIME type: ${mimeType}`);
          //console.log(mimeType)
          if(mimeType == 'image/png' || mimeType == 'image/jpeg') {
            //formSubmit.disabled = false;
            fileUpload.nextElementSibling.innerHTML = ''
          } else {
            console.log('not valid')
            fileUpload.nextElementSibling.innerHTML = 'Please use .JPG or .PNG file format.'
          }
        } catch (error) {
          console.error(error);
        }
      }
    });

  });
  /*
  fileInput.addEventListener('change', async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const mimeType = await detectMimeType(file);
        console.log(`MIME type: ${mimeType}`);
        console.log(mimeType)
        if(mimeType == 'image/png' || mimeType == 'image/jpeg') {
          formSubmit.disabled = false;
        } else {
          console.log('not valid')
          formSubmit.disabled = true;
        }
      } catch (error) {
        console.error(error);
      }
    }
  });*/

  let formSubmitCheck = document.querySelector('.check-captcha-sponsorship');
  let fileInputs = document.querySelectorAll('input[type="file"]');
  formSubmitCheck.addEventListener('click', async (event) => {
    
    let fileFalse = 0;
    let fileTrue = 0;
    fileInputs.forEach(temp => {
      //console.log(temp);
      const tempFile = temp.files[0];
      //console.log(tempFile);
      if (tempFile) {

        if(tempFile.type == 'image/png' || tempFile.type == 'image/jpeg' || tempFile.type == 'undefined') {
          fileTrue++;
        } else {
          fileFalse++;
        }
        console.log(tempFile.type);
        /*try {
          let tempmimeType = detectMimeType(tempFile);
          console.log(`MIME type: ${tempmimeType}`);
        } catch (error) {
          console.error(error);
        }*/
      }
    })
    //console.log(fileFalse)
    //console.log(fileTrue);
    if(fileFalse == 0) {
      submitSponsorshipForm();
    } else {
      formSubmitCheck.nextElementSibling.innerHTML = 'Please use .JPG or .PNG file format.'
    }
  });
}

function submitSponsorshipForm() {
  let formSubmitCheck = document.querySelector('.check-captcha-sponsorship');
  formSubmitCheck.nextElementSibling.innerHTML = ''
  const form = event.target.closest('form');
  const captchaId = form.dataset.captchaId;

  console.log(grecaptcha.getResponse(captchaId));

  if (grecaptcha.getResponse(captchaId) === '') {
    var response = form.querySelector('#check-captcha');
    response.innerHTML = 'Captcha box not selected';
    return false;
  } else {
    //const contactSubmit = form.querySelector('input[type="submit"]');
    const contactSubmit = form.querySelector('#contact-form');
    //console.log('contact submit', contactSubmit);
    form.requestSubmit(contactSubmit);
    return true;
  }
  return false;
}