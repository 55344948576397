let instagramDisplay = 0;

if (window.outerWidth > 640) {
    instagramDisplay = 5;
} else {
    instagramDisplay = 4;
}

/*
<li class='column column-block'>
    <a href=\"{{link}}\"><img src=\"{{image}}\" /></a>
</li>
*/

if ($('#instafeed').length) {
    var feed = new Instafeed({
        clientID: "d5841a77448b43819b566045d63f7e3b",
        accessToken: "405308993.1677ed0.fcb3f0c975814833a9982257f4b86137",
        get: "user",
        userId: 405308993,
        limit: 60,
        resolution: "standard_resolution",
        template: `
        <div class="column">
            <a href="{{image}}" 
                class="instainfo" 
                target="_blank" 
                data-caption="{{caption}}" 
                data-username="{{model.user.username}}"
                data-profilepic="{{model.user.profile_picture}}"
                data-bio="{{model.user.bio}}"
                data-link="{{link}}"
                style="background-image:url('{{image}}');
            ">
            </a>
        </div>`,
        after: function () {
            $('#instafeed').slick({
                arrows: false,
                dots: false,
                focusOnSelect: false,
                infinite: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 0,
                speed: 9000,
                cssEase: 'linear',
                responsive: [{
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                }]
            });

            $('.instainfo').click(function instaPopup(e) {
                // prevent hyperlink
                e.preventDefault();
                // setup variables from instagram data
                var $this = $(this);
                var $getUsername = $this.data('username');
                var $getCaption = $this.data('caption');
                var $getProfilePic = $this.data('profilepic');
                var $getBio = $this.data('bio');
                var $getLink = $this.data('link');
                var $getInstagramLink = '//www.instagram.com/richline_motorsports_/';
                var $getImage = $this.attr('href');

                // implement into popup
                $('#instagram-popup')
                    .find('.instagram-popup-profilepic')
                    .attr('src', $getProfilePic);
                $('#instagram-popup')
                    .find('.instagram-popup-page')
                    .attr('href', $getInstagramLink);
                $('#instagram-popup')
                    .find('.instagram-popup-username')
                    .html($getUsername)
                    .attr('href', $getInstagramLink);
                $('#instagram-popup')
                    .find('.instagram-popup-link')
                    .attr('href', $getLink);
                $('#instagram-popup')
                    .find('.instagram-popup-img')
                    .attr('src', $getImage);
                $('#instagram-popup')
                    .find('.instagram-popup-caption')
                    .html($getCaption);

                // run magnific popup with variables set
                $.magnificPopup.open({

                    items: {
                        src: '#instagram-popup',
                        type: 'inline'
                    },
                    gallery: {
                        enabled: true,
                        navigateByImgClick: true,
                        preload: [0, 1]
                    }
                });
            });
        }
    });
}
if ($(".instagram-container").length > 0) {
    feed.run();
}