///// non-video slider ////
$('.js-home-slider').slick({
  autoplay: true,
  dots: true,
  arrows: false,
  adaptiveHeight: true,
  autoplaySpeed: 4000,
});


///// Video slider /////
//
//if ($('.js-home-slider').length) {
//  let frontPageSlider = $('.js-home-slider');
//  frontPageSlider.slick({
//      infinite:true,
//      autoplay: false,
//      dots: false,
//      arrows: false,
//      adaptiveHeight: true,
//      autoplaySpeed: 4000,
//      responsive: [{
//        breakpoint: 600,
//        settings: {
//          autoplay: true,
//          adaptiveHeight: false
//        }
//      }]
//  });
//  if ( $(window).width() < 640 ) {
//    frontPageSlider.slick('slickRemove', 0);
//  } else {
//    
//    // 2. This code loads the IFrame Player API code asynchronously.
//    var tag = document.createElement('script');
//
//    tag.src = "//www.youtube.com/iframe_api";
//    var firstScriptTag = document.getElementsByTagName('script')[0];
//    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
//
//    // 3. This function creates an <iframe> (and YouTube player)
//    //    after the API code downloads.
//    var player;
//
//    function onYouTubeIframeAPIReady() {
//      player = new YT.Player('player', {
//        videoId: document.getElementById('player').dataset.id,
//        playerVars: {
//          controls: 0,
//          modestbranding: 1,
//          rel: 0,
//          showinfo: 0,
//        },
//        events: {
//          'onReady': onPlayerReady,
//          'onStateChange': onPlayerStateChange
//        }
//      });
//    }
//    // 4. The API will call this function when the video player is ready.
//    function onPlayerReady(event) {
//      player.mute();
//      event.target.playVideo();
//    }
//
//    // 5. The API calls this function when the player's state changes.
//    function onPlayerStateChange(e) {
//      /*
//      if (e.data == 0) {
//        frontPageSlider.slick('slickNext');
//        frontPageSlider.slick('slickPlay');
//      }*/
//    }
///*
//    $('.js-home-slider').on('afterChange', function (event, slick, currentSlide, nextSlide) {
//      if (currentSlide === 0) {
//        frontPageSlider.slick('slickPause');
//        player.playVideo();
//      }
//    });
//  */  
//  }
//  
//  
//
//
//}
//
//(function setbackgroundLink() {
//  if ($('.backgroundLink').length > 0) {
//    $('.backgroundLink').attr('href', $('.backgroundSlide--large .slick-active').data('link'));
//  }
//})();
//
//$('.js-home-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
//  var getLink = $(slick.$slides.get(nextSlide)).data('link');
//  $('.js-home-slider').attr('href', getLink);
//});
//
///// END : Video Slider /////


if ($('.hp').length) {
    if ($(window).width() < 640) {
        $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
        $('.js-footer-social').insertAfter('.support-list');
    }

    var moveHTML = debounce(function () {
        if ($(window).width() < 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
            $('.js-footer-social').insertAfter('.support-list');
        }
        if ($(window).width() > 640) {
            $('.js-vehicle-select').insertAfter('.vehicle-select__header');
            $('.js-footer-social').insertAfter('.footer__about');
        }
    }, 500);

    window.addEventListener('resize', moveHTML);


  // search bar
  $('#wheels-specs-select.wheels-by-size select').change(function(){
    $('#wheels-specs-select.wheels-by-size .button[disabled]').prop('disabled',false);

  });
  $('#wheels-specs-select.tires-by-size select').change(function(){
    $('#wheels-specs-select.tires-by-size .button[disabled]').prop('disabled',false);
  });

  $('.fitment button').click(function(){
    loadSpinner();
  });

  // packages search
  $('#package-specials-select select').change(function getModels(){
    console.log($(this).val());
    let make = $(this).val(); 

    let url = '/ajax/wheelstires-getModels.cfm?make=' + make;
    $.get(url, function getSpecs(
      data
    ) {
      $('#package-specials-select')
        .html(data)
        .prop('disabled', false);
    });
  });
}


// helpful functions
function trimString(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}
function removeVal(obj,value){
   var arrObj = obj.split(',')
   if(arrObj.length > 1){
   for( var i = 0; i < arrObj.length; i++){ 
           if ( arrObj[i] === value) {
                  arrObj.splice(i, 1); 
           }
   }
   obj = arrObj.join(",")
   }else{
        obj ='';
   }
   return obj;
}