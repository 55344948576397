if ($('#tires,#tire-brands').length) {
  
  // tires header 
  
  // hide header models

  $('.hide-model').hide();

  // tires header - show more models
  $('.tires-see-more a').click(function(){
    $('.hide-model').show();
    $('.tires-see-more').hide();
  });
  
  // product filter
  $(".product-filter input[type='checkbox']").change(function() {
    loadSpinner();
    //$('.filter-form').submit();
  });
  $(".product-filter.filter-active a").click(function() {
    loadSpinner();
  });
  /*
  $(".filter-form input[type='checkbox']").change(function() {
    console.log($(this));
    console.log($(this).val());
    //loadScreen();
    //$('.filter-form').submit();
  });
  */
  
  
  // product filter - diameter ///////////////
  // collect existing diameter
  var $diameters = $('#diameterCollection');
  var $collDiameter = $diameters.val();
  
  // if change update 
  $('.item-diameter').change(function() {
    
    // clear existing
    $collDiameter = '';
    
    // loop through new selection
    $('.item-diameter').each(function() {
      if(this.checked) {
        $collDiameter += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collDiameter = trimString($collDiameter);
    
    // update collection
    $diameters.val($collDiameter);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selecteddiameter").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collDiameter = removeVal($collDiameter,val)    
    
    // replace values
    $diameters.val($collDiameter);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - width ///////////////
  // collect existing width
  var $widths = $('#widthCollection');
  var $collWidth = $widths.val();
  
  // if change update 
  $('.item-width').change(function() {
    
    // clear existing
    $collWidth = '';
    
    // loop through new selection
    $('.item-width').each(function() {
      if(this.checked) {
        $collWidth += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collWidth = trimString($collWidth);
    
    // update collection
    $widths.val($collWidth);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedwidth").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collWidth = removeVal($collWidth,val)    
    
    // replace values
    $widths.val($collWidth);
    
    // submit form
    $('.filter-form').submit();
  });


  // product filter - aspect ///////////////
  // collect existing aspect
  var $aspects = $('#aspectCollection');
  var $collAspect = $aspects.val();
  
  // if change update 
  $('.item-aspect').change(function() {
    
    // clear existing
    $collAspect = '';
    
    // loop through new selection
    $('.item-aspect').each(function() {
      if(this.checked) {
        $collAspect += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collAspect = trimString($collAspect);
    
    // update collection
    $aspects.val($collAspect);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedaspect").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collAspect = removeVal($collAspect,val)    
    
    // replace values
    $aspects.val($collAspect);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - type ///////////////
  // collect existing type
  var $type = $('#typeCollection');
  var $collType = $type.val();
  
  // if change update 
  $('.item-type').change(function() {
    
    // clear existing
    $collType = '';
    
    // loop through new selection
    $('.item-type').each(function() {
      if(this.checked) {
        $collType += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collType = trimString($collType);
    
    // update collection
    $type.val($collType);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedtype").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collType = removeVal($collType,val)    
    
    // replace values
    $type.val($collType);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - bolt pattern ///////////////
  // collect existing bolt pattern
  var $bolts = $('#boltpatCollection');
  var $collBolt = $bolts.val();
  
  // if change update 
  $('.item-bolt').change(function() {
    
    // clear existing
    $collBolt = '';
    
    // loop through new selection
    $('.item-bolt').each(function() {
      if(this.checked) {
        $collBolt += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collBolt = trimString($collBolt);
    
    // update collection
    $bolts.val($collBolt);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedbolt").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collBolt = removeVal($collBolt,val)    
    
    // replace values
    $bolts.val($collBolt);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - brand ///////////////
  // collect existing brand
  var $brands = $('#brandCollection');
  var $collBrand = $brands.val();
  // collect existing style
  var $styles = $('#styleCollection');
  var $collStyle = $styles.val();
  
  // if change update 
  $('.item-brand').change(function() {
    
    // clear existing
    $collBrand = '';
    $collStyle = '';
    
    // loop through new selection
    $('.item-brand').each(function() {
      if(this.checked) {
        $collBrand += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collBrand = trimString($collBrand);
    
    // update collection
    $brands.val($collBrand);
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedbrand").click(function() {
    console.log('cllick');
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collBrand = removeVal($collBrand,val)    
    $collStyle = '';
    
    // replace values
    $brands.val($collBrand);
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - styles ///////////////
  // if change update 
  $('.item-style').change(function() {
    
    // clear existing
    $collStyle = '';
    
    // loop through new selection
    $('.item-style').each(function() {
      if(this.checked) {
        $collStyle += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collStyle = trimString($collStyle);
    
    // update collection
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedstyle").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collStyle = removeVal($collStyle,val)    
    
    // replace values
    $styles.val($collStyle);
    
    // submit form
    $('.filter-form').submit();
  });
  // header styles
  $('.tire-style').click(function(){
    var selecledStyle = $(this).attr('data-val');

    // loop through new selection
    $('.item-style').each(function() {
      var checkboxValue = $(this).val();

      if(selecledStyle == checkboxValue) {
        $(this).prop('checked',true);
        $(this).trigger('change');
      }
    })
  });
  
  
  // product filter - construction ///////////////
  // collect existing construction
  var $constructions = $('#constructionCollection');
  var $collConstruction = $constructions.val();
  
  // if change update 
  $('.item-construction').change(function() {
    
    // clear existing
    $collConstruction = '';
    
    // loop through new selection
    $('.item-construction').each(function() {
      if(this.checked) {
        $collConstruction += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collConstruction = trimString($collConstruction);
    
    // update collection
    $constructions.val($collConstruction);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedconstruction").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collConstruction = removeVal($collConstruction,val)    
    
    // replace values
    $constructions.val($collConstruction);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - finish ///////////////
  // collect existing finish
  var $finishes = $('#finishCollection');
  var $collFinish = $finishes.val();
  
  // if change update 
  $('.item-finish').change(function() {
    
    // clear existing
    $collFinish = '';
    
    // loop through new selection
    $('.item-finish').each(function() {
      if(this.checked) {
        $collFinish += $(this).val() + ',';
      }
    })
    
    // remove last comma
    $collFinish = trimString($collFinish);
    
    // update collection
    $finishes.val($collFinish);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // remove from active
  $(".selectedfinish").click(function() {
    // acquire selected value
    var val = $(this).attr('data-val');

    // remove selected value from collection
    $collFinish = removeVal($collFinish,val)    
    console.log($collFinish);
    
    // replace values
    $finishes.val($collFinish);
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // product filter - search ////////////
  var $search = $('#searchCollection');
  
  $('.filter-search-field button').click(function(){
    // replace values
    var $searchValue = $('#search').val();
    $search.val($searchValue);
    
    // submit form
    $('.filter-form').submit();
  });
  
  // search by hiting enter
  $('.filter-search-field input').keypress(function(e){
    // replace values
    var $searchValue = $('#search').val();
    $search.val($searchValue);
    
    if (e.which == 13) {
      $(this).blur();
      // submit form
      $('.filter-form').submit();
    };
  });
  
  // remove from active
  $(".searchPhrase").click(function() {

    // remove selected value from collection
    $search.val('');
    
    // submit form
    $('.filter-form').submit();
  });

  // product filter - search tire ////////////
  var $searchTire = $('#searchTireCollection');
  
  // search by hiting enter
  $('.tires-name-search input').keypress(function(e){
    // replace values
    var $searchTireValue = $('#searchtire').val();
    $searchTire.val($searchTireValue);
    
    if (e.which == 13) {
      $(this).blur();
      // submit form
      $('.filter-form').submit();
    };
  });
  
  // remove from active
  $(".searchTirePhrase").click(function() {

    // remove selected value from collection
    $searchTire.val('');
    
    // submit form
    $('.filter-form').submit();
  });
  
  
  // clear selection 
  $('.filter-active-clear a').click(function(){
    // remove values
    $diameters.val('');
    $widths.val('');
    $aspects.val('');
    $type.val('');
    $brands.val('');
    $styles.val('');
    $('#searchCollection').val('');
    $('#searchTireCollection').val('');
    
    // submit form
    $('.filter-form').submit();
  });
  

  // filter display 
  if($diameters.val() !== '') {
    $('.filter-diameter .filter-title').trigger('click');
    $('.filter-diameter .filter-button').trigger('click');
  }
  if($widths.val() !== '') {
    $('.filter-widths .filter-title').trigger('click');
    $('.filter-widths .filter-button').trigger('click');
  }
  // filter display 
  if($aspects.val() !== '') {
    $('.filter-aspects .filter-title').trigger('click');
    $('.filter-aspects .filter-button').trigger('click');
  }
  if($type.val() !== '') {
    $('.filter-types .filter-title').trigger('click');
    $('.filter-types .filter-button').trigger('click');
  }
  // filter display 
  if($brands.val() !== '' && $brands.val() !== '0') {
    $('.filter-brands .filter-title').trigger('click');
    $('.filter-brands .filter-button').trigger('click');
  }
  if($models.val() !== '') {
    $('.filter-models .filter-title').trigger('click');
    $('.filter-models .filter-button').trigger('click');
  }
  
  
  
} // end wheels


// helpful functions
function trimString(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}
function removeVal(obj,value){
   var arrObj = obj.split(',')
   if(arrObj.length > 1){
   for( var i = 0; i < arrObj.length; i++){ 
           if ( arrObj[i] === value) {
                  arrObj.splice(i, 1); 
           }
   }
   obj = arrObj.join(",")
   }else{
        obj ='';
   }
   return obj;
}