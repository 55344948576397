$('.js-trim-select').on('change', function() {
  var value = $(this).val();
  var url = '/ajax/connector.cfc?method=setBodyType&strBodyType=' + value;
  $.ajax({
    method: 'POST',
    url: url
  }).done(function() {
    window.location.href = '/accessory-brands/';
  });
});

$('.js-filterCapType').change(function changeMake() {
  $('#accessory-search form').submit();
});

// quantity update
quantityUpdate($('#product-quantity'));
$('#product-quantity').change(function(){
  quantityUpdate($(this));
});
function quantityUpdate(quantity){
  let itemQuantity = quantity.val();
  $('.snipcart-add-item').attr('data-item-quantity',itemQuantity);
}

if($('#accessory').length) {
  $('.buy-toggle').click(function(){
    $('.vehicle-info').slideToggle();
    $(this).toggleClass('buy-active')
  });
  //let cartButton = $('.vehicle-info .snipcart-add-item');
  //cartButton.attr('disabled','disabled');
  $('input[name="ymm"]').keyup(function () {
    $('.snipcart-add-item').attr('data-item-custom1-value',this.value);
    /*
    if($('input[name="ymm"]').val().length > 0) {
      cartButton.removeClass('disabled').removeAttr('disabled');
    } else {
      cartButton.addClass('disabled').attr('disabled','disabled');
    }*/
  });
  $('input[name="modification"]').keyup(function () {
    $('.snipcart-add-item').attr('data-item-custom2-value',this.value);
    /*
    if($('input[name="ymm"]').val().length > 0) {
      cartButton.removeClass('disabled').removeAttr('disabled');
    } else {
      cartButton.addClass('disabled').attr('disabled','disabled');
    }*/
  });
  $('.initiate-buy').click(function(){
    let ymmField = $('input[name="ymm"]');
    let modField = $('input[name="modification"]');
    if(ymmField.val() == '') {
      ymmField.addClass('missing');
    } else if (modField.val() == '') {
      modField.addClass('missing')
    } else {
      $('.snipcart-add-item').trigger('click');
    }
  });
}