if ($('#wheels-tires').length) {
  console.log('hello there')
  // packages search
  $('#package-specials-select select').change(function getModels(){
    console.log($(this).val());
    let make = $(this).val(); 

    let url = '/ajax/wheelstires-getModels.cfm?make=' + make;
    $.get(url, function getSpecs(
      data
    ) {
      $('#package-specials-select')
        .html(data)
        .prop('disabled', false);
    });
  });
  
  // search bar
  $('#wheels-specs-select.wheels-by-size select').change(function(){
    $('#wheels-specs-select.wheels-by-size .button[disabled]').prop('disabled',false);

  });
  $('#wheels-specs-select.tires-by-size select').change(function(){
    $('#wheels-specs-select.tires-by-size .button[disabled]').prop('disabled',false);
  });

  $('.search-bar button').click(function(){
    loadSpinner();
  });
} // end wheels


// helpful functions
function trimString(obj){
  var L = (obj.length -1);
  obj = obj.substring(0, L);
  return obj;  
}
function removeVal(obj,value){
   var arrObj = obj.split(',')
   if(arrObj.length > 1){
   for( var i = 0; i < arrObj.length; i++){ 
           if ( arrObj[i] === value) {
                  arrObj.splice(i, 1); 
           }
   }
   obj = arrObj.join(",")
   }else{
        obj ='';
   }
   return obj;
}